<template>
    <TaskPageSwitch 
        main
        model="tasks.TaskModel"
        :tableType="tableType"
        :hash="false"
        :taskType="taskType"
        :showPageTitle="showPageTitle"
        :pageConfig="pageConfig"
        :name="$route.name"
        :pageName="page_name">
        <slot />
    </TaskPageSwitch>
</template>

<script>
import viewProps from '../../mixins/viewProps.js'
import '../../assets/main.scss'
import TaskPageSwitch from '../../components/TaskList/TaskPageSwitch.vue'
import { mapState } from 'vuex'

export default {
    name: 'TaskViewTable',
    components: { 
        TaskPageSwitch 
    },
    mixins: [viewProps],
    props: {
        page_name: {
            type: String,
            default: ''
        },
        tableType: {
            type: String,
            default: 'tasks'
        },
        showPageTitle: {
            type: Boolean,
            default: false
        }

    },
    computed: {
        ...mapState({
            viewType: state => state.task.mobileViewType
        }),
        isMobile() {
            return this.$store.state.isMobile
        },
        isKanban() {
            return this.viewType[this.taskType] === 'kanban'
        },
        hasPadding() {
            return !this.isMobile || !this.isKanban
        }
    }

}
</script>

<style lang="scss">
.table_page{ 
    .list_table{
        display: flex;
        flex-direction: column;
        height: 100%;
        .table_container{
            flex-grow: 1;
        }
    }
}
</style>